import React from 'react';

type Props = {
  onPressLeftBtn?: () => void;
  onPressRightBtnLink?: string;
  leftBtnTitle?: string;
  rightBtnTitle?: string;
};

function AuctionButtons({
  onPressLeftBtn = () => {},
  onPressRightBtnLink = '#',
  leftBtnTitle = '',
  rightBtnTitle = '',
}: Props) {
  return (
    <div className='flex align-center auction-buttons-container'>
      {/* <button
        className='flex align-center justify-center btn-trailer'
        onClick={onPressLeftBtn}
      >
        {leftBtnTitle}
      </button> */}
      <a
        href={onPressRightBtnLink}
        target='_blank'
        rel='noreferrer noopener'
        style={{ textDecoration: 'none', textTransform: 'capitalize' }}
        className='btn-cta'
      >
        {rightBtnTitle.toUpperCase()}
      </a>
    </div>
  );
}

export { AuctionButtons };
