import { makeAutoObservable, toJS } from "mobx";

import { RootStore } from "./RootStore";

// Object representing a token and the user's balance of the token
export type BalanceType = {
  name: string;

  balance: any;
};

export class BalanceStore {
  rootStore: RootStore;

  latestBalance: BalanceType[];

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);

    // initiate rootStore if we need to access other mobx stores from here

    this.rootStore = rootStore;

    const initialBalances = [];
    const tokensToTrack = rootStore.tokenStore.getTokensToTrack();
    initialBalances.push({
      name: "ETH",
      balance: 0,
    });
    for (let token of tokensToTrack) {
      initialBalances.push({
        name: token.name,
        balance: 0,
      })
    }
    this.latestBalance = initialBalances;
    
  }

  getLatestBalances() {
    return this.latestBalance;
  }

  getBalanceOf(tokenName: string) {
    const tokenToFind = this.latestBalance.find(
      (balance) => balance.name === tokenName
    );
    if (tokenToFind) {
      return tokenToFind.balance;
    }
  }

  updateLatestBalance(tokenName: string, newBalance: number) {
    console.log("Updating latest balance of " + tokenName + " to " + newBalance.toString());

    this.latestBalance = [
      ...this.latestBalance.filter((element) => element.name !== tokenName),

      { name: tokenName, balance: newBalance },
    ].sort((a: BalanceType, b: BalanceType) => {
      if (a.name < b.name) {
        return -1;
      }

      if (a.name > b.name) {
        return 1;
      }

      return 0;
    });

    return this.latestBalance;
  }
}
