import React, { CSSProperties } from 'react';

const linkToWhitePaper = 'https://whitepaper.avocadodao.io/';
const linkToCommunity = 'https://avocadoguild.com';

function FooterLinks() {
  return (
    <table className='footer-links-section'>
      <thead>
        <tr style={{ height: 45 }}>
          <th style={{ width: '50%' }}>
            <p className='headline'>Quick Links</p>
          </th>
          <th style={{ width: '50%' }}>
            <p className='headline'>Resources</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <p className='footer-link'>Home</p>
          </td>
          {/* <td>
            <p className='footer-link'>More coming soon</p>
          </td> */}
          <td>
            <a
              href={linkToWhitePaper}
              target='_blank'
              rel='noreferrer noopener'
              style={{ textDecoration: 'none' }}
            >
              <p className='footer-link'>White Paper</p>
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <a
              href={linkToCommunity}
              target='_blank'
              rel='noreferrer noopener'
              style={{ textDecoration: 'none' }}
            >
              <p className='footer-link community-link-footer'>Community</p>
            </a>
          </td>
          <td>
            <p className='footer-link'>More coming soon</p>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export { FooterLinks };
