import React, { CSSProperties, useEffect, useState } from "react";
import { PartnersAutoScroll } from "./PartnersAutoScroll";
import { PartnerType } from "./PartnersSection";

type Props = {
  partners?: PartnerType[];
};

function PartnersDisplay({ partners = [] }: Props) {
  const [width, setWidth] = useState(window.innerWidth);
  const LAPTOP_WIDTH = 1024;
  const MOBILE_WIDTH = 540;
  const TABLET_WIDTH = 850;

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  type ItemProps = {
    partner: PartnerType;
  };

  function PartnerItem({ partner }: ItemProps) {
    let logosMaxWidth;
    let logosMaxHeight;
    let logosMinHeight;

    const setMinSizes =
      partner.name === "MEXC Global" ||
      partner.name === "LD Capital" ||
      partner.name === "Kenetic Holdings";

    const overflowWidth = 1800;
    const is4K = width >= 3840;

    if (width <= overflowWidth) {
      logosMaxWidth = partner.name === "Three Arrows Capital" ? width / 5.8 : width / 9;
      logosMaxHeight = partner.name === "Animoca Brands" ? 70 : 90;
      logosMinHeight = partner.name === "LD Capital" ? 65 : 30;
    }
    else if(is4K) {
      logosMaxWidth = partner.name === "Three Arrows Capital" ? width / 3 : width / 8;
      logosMaxHeight = partner.name === "Animoca Brands" ? 150 : 120;
      logosMinHeight = partner.name === "LD Capital" ? width / 25 : width / 42;
    }
    else {
      logosMaxWidth = partner.name === "Three Arrows Capital" ? width / 5.7 : width / 8.2;
      logosMaxHeight = partner.name === "Animoca Brands" ? 95 : 130;
      logosMinHeight = partner.name === "LD Capital" ? width / 27 : width / 47;
    }
    return (
      <div className="flex justify-center align-center partner-logo">
        <img
          src={partner.logo}
          alt={partner.name}
          style={{
            maxHeight: !setMinSizes ? logosMaxHeight : undefined,
            maxWidth: !setMinSizes ? logosMaxWidth : undefined,
            minHeight: setMinSizes ? logosMinHeight : undefined,
          }}
        />
      </div>
    );
  }

  if (width <= TABLET_WIDTH) {
    return <PartnersAutoScroll partners={partners} />;
  }
  return (
    <div className="flex flex-column justify-center align-center">
      <div className="flex flex-row justify-center">
        {partners.length > 0 ? <PartnerItem partner={partners[0]} /> : null}
        {partners.length > 1 ? <PartnerItem partner={partners[1]} /> : null}
        {partners.length > 2 ? <PartnerItem partner={partners[2]} /> : null}
        {partners.length > 3 ? <PartnerItem partner={partners[3]} /> : null}
      </div>
      <div className="flex flex-row justify-center">
        {partners.length > 4 ? <PartnerItem partner={partners[4]} /> : null}
        {partners.length > 5 ? <PartnerItem partner={partners[5]} /> : null}
        {partners.length > 6 ? <PartnerItem partner={partners[6]} /> : null}
        {partners.length > 7 ? <PartnerItem partner={partners[7]} /> : null}
        {partners.length > 8 ? <PartnerItem partner={partners[8]} /> : null}
      </div>
      <div className="partners-list flex flex-row justify-center flex-wrap">
        {partners.map((element, index) => {
          if (index > 8) {
            return <PartnerItem key={index.toString()} partner={element} />;
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
}

export { PartnersDisplay };
