import { ContractType } from "data/store/TokenStore";

const MC_ABI = [
    {
        constant: true,
        inputs: [
            {
                name: "_owner",
                type: "address"
            }
        ],
        name: "balanceOf",
        outputs: [
            {
                name: "balance",
                type: "uint256"
            }
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
];

export const MC_CONTRACT: ContractType = {
  address: "0x949d48eca67b17269629c7194f4b727d4ef9e5d6",
  unit: "ether",
  abi: MC_ABI,
};
