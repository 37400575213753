import { useContext, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { StoreContext } from 'index';
import Onboarding from '@metamask/onboarding';
import MetaMaskOnboarding from '@metamask/onboarding';
import { AuctionButtons, WalletModal } from 'components';
import MetaMaskIcon from 'assets/images/icons/meta-mask.svg';
import { useHistory } from 'react-router-dom';
import { HeaderMainSection } from './HeaderMainSection';

type Props = {};

const linkToWhitePaper = 'https://whitepaper.avocadodao.io/';
const linkToToken =
  'https://pancakeswap.finance/swap?outputCurrency=0xa41f142b6eb2b164f8164cae0716892ce02f311f';

const MOBILE_WIDTH = 480;
const IPAD_WIDTH = 1024;
const DESKTOP_WIDTH = 2400;

export const MainSection = observer(({}: Props) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const { innerWidth: width } = window;
  const onboarding = useRef<Onboarding>();

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  function onAfterOpen() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
    console.log('close modal');
  }

  return (
    <div
      className='flex flex-column align-center'
      style={{
        height: width < MOBILE_WIDTH ? '580px' : 'calc(100vw / 1.40625)',
        minHeight:
          width < MOBILE_WIDTH
            ? 'unset'
            : width < IPAD_WIDTH
            ? 'unset'
            : '600px',
        overflow: 'hidden',
      }}
    >
        <HeaderMainSection
          // leftBtnTitle='Read White Paper'
          scholarshipInfo=''
          // onPressLeftBtn={() =>
          //   window.open(linkToWhitePaper, '_blank')?.focus()
          // }
        />      
      <div className='main-section flex flex-column align-center'>
        {/* <h1
          className='headline-medium'
          style={{ marginBottom: width < MOBILE_WIDTH ? '6px' : '13px' }}
        >
          AVOCADO DAO
        </h1> */}
        <h2 
        className='title main-heading spacing'
        style={{ marginBottom: width < MOBILE_WIDTH ? '6px' : '13px',
                 marginTop: width < MOBILE_WIDTH ? '120px' : '100px' }}
        >Providing More to Many</h2>
        <p className='body subheading'>
          Unlock life-changing opportunities with Avocado DAO Tokens
        </p>
        {/* <p className='body subheading highlight'></p> */}
        <AuctionButtons
          // leftBtnTitle='Read White Paper'
          rightBtnTitle='Buy Avg Token'
          // onPressLeftBtn={() =>
          //   window.open(linkToWhitePaper, '_blank')?.focus()
          // }
          onPressRightBtnLink={linkToToken}
        />
      </div>
      <WalletModal
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        closeModal={closeModal}
        width={width}
      />
    </div>
  );
});
