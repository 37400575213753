import React from "react";

import MetaverseDesktopSection from "./MetaverseDesktopSection";
import MetaverseMobileSection from "./MetaverseMobileSection";

const MetaverseSection = () => {
  return (
    <div className="metaverse-section flex flex-column align-center">
      <div className="title-xl metaverse-heading justify-center">
        The MetaVerse
      </div>
      <div className="text-wrap">
        <p className="body">Our Gaming Partners</p>
      </div>

      <MetaverseMobileSection />
      <MetaverseDesktopSection />
    </div>
  );
};

export { MetaverseSection };
