import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { LandingPage, Dashboard, Bridge } from 'screens';
import { observer } from 'mobx-react-lite';
import { StoreContext } from 'index';

const openRoutes = ['/'];

export const ROUTES = [
  {
    path: '/',
    key: 'ROOT',
    exact: true,
    component: () => <LandingPage />,
  },
  {
    path: '/bridge',
    key: 'APP_ROOT',
    exact: true,
    component: () => <Bridge />,
  },
  {
    path: '/app',
    key: 'APP',
    component: observer((props: any) => {
      return <RenderRoutes {...props} />;
    }),
    routes: [
      {
        path: '/app',
        key: 'APP_ROOT',
        exact: true,
        component: () => <Dashboard />,
      },
      {
        path: '/app/page',
        key: 'APP_PAGE',
        exact: true,
        component: () => <h1>App Page</h1>,
      },
    ],
  },
];

const RouteWithSubRoutes = observer((route: any) => {
  const store = useContext(StoreContext);

  if (openRoutes.includes(route.path)) {
    return (
      <Route
        path={route.path}
        exact={route.exact}
        render={(props) => <route.component {...props} routes={route.routes} />}
      />
    );
  } else {
    const accounts = store?.appStore.getAccounts();
    return (
      <Route
        path={route.path}
        exact={route.exact}
        render={(props) =>
          <route.component {...props} routes={route.routes} />
        }
      />
    );
  }
});

export function RenderRoutes({ routes }: { routes: any }) {
  return (
    <Switch>
      {routes.map((route: any, i: number) => {
        return <RouteWithSubRoutes key={route.key} {...route} />;
      })}
      <Route component={() => <h1>Not Found!</h1>} />
    </Switch>
  );
}

export default ROUTES;
