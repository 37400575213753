import { useContext } from 'react';

import { StoreContext } from 'index';

import portal from 'assets/images/objects-compressed/portal-1.png';
import spaceship from 'assets/images/objects-compressed/ag-spaceship.png';
import green_planet_2 from 'assets/images/objects-compressed/green-planet-2.png';
import play_button from 'assets/images/play-button.png';
import video_placeholder from 'assets/images/video-placeholder-2.png';

const discordMembers = '61k+';
const scholars = (11000).toLocaleString() + '+';
const slpEarned = '95M+';
const linkToToken =
  'https://app.uniswap.org/#/swap?inputCurrency=0xa41f142b6eb2b164f8164cae0716892ce02f311f&outputCurrency=ETH';
const linkToDiscord = 'https://discord.gg/avocadoguild';

export function GuildSection() {
  
  const store = useContext(StoreContext);

  return (
    <div className='guild-section token-sale-section flex flex-column'>
      <div className='guild-objects'>
        {/* <img
          src={green_planet_1}
          alt='green planet'
          className='green-planet-1 token-sale'
        /> */}
        <img
          src={green_planet_2}
          alt='green planet'
          className='green-planet-2'
        />
      </div>
      <div className='portal-container'>
        <img src={portal} alt='portal' className='portal' />
      </div>
      <img src={spaceship} alt='spaceship' className='spaceship' />
      <div className=' flex flex-column align-center'>
        <div className='guild-heading token-sale-heading flex flex-row container token-sale-heading-small'>
          <div className='video-placeholder flex flex-column'>
            <img
              src={video_placeholder}
              alt='video placeholder'
              className='video-placeholder-img'
            />
            <div
              onClick={() => store?.appStore.setShowVideoModal(true)}
              style={{ cursor: 'pointer' }}
            >
              <div className='play-button'>
                <img src={play_button} alt='play-button' />
              </div>
            </div>
          </div>
          <div className='guild-heading-column flex flex-column container '>
            <div className='guild-header token-sale flex flex-column'>
              <p className='headline'>BUILDING COMMUNITIES THROUGH P2E</p>
              <p className='title-xl guild-header-title token-sale'>
                Making real world impact through virtual contribution
              </p>
            </div>
            <div className='guild-body token-sale spacing flex flex-column align-start'>
              <p className='body element token-sale-element spacing'>
                <b>Avocado Dao</b> is the largest community of gamers cohesively
                building a better future through P2E blockchain gaming.
              </p>
            </div>
            <div className='buttons-row flex align-center'>
              <a
                href={linkToToken}
                target='_blank'
                rel='noreferrer'
                style={{ textDecoration: 'none' }}
              >
                <button className='btn-hero btn-connect btn-auction btn-live-auction spacing'>
                  BUY AVG TOKEN
                </button>
              </a>
              <a
                href={linkToDiscord}
                target='_blank'
                rel='noreferrer'
                style={{ textDecoration: 'none' }}
              >
                <button className='btn-text-semi-bold header-white-paper spacing btn-discord'>
                  Join Our Discord
                </button>
              </a>
            </div>

            <div className='guild-stats token-sale spacing flex flex-wrap'>
              <div className='guild-stats-element spacing token-sale flex flex-column'>
                <div className='title-md numbers token-sale-numbers'>{discordMembers}</div>
                <p className='headline token-sale-headline'>Discord Members</p>
              </div>
              <div className='guild-stats-element spacing token-sale flex flex-column'>
                <div className='title-md numbers token-sale-numbers'>{scholars}</div>
                <p className='headline token-sale-headline'>Scholars</p>
              </div>
              <div className='guild-stats-element spacing token-sale flex flex-column'>
                <div className='title-md numbers token-sale-numbers'>{slpEarned}</div>
                <p className='headline token-sale-headline'>Total SLP earned</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
