import React, { CSSProperties } from "react";
import AvocadoDAOLogo from "../../assets/images/logos/avo-full-logo.png";
import { CopyrightSection } from "./CopyrightSection";
import { FooterLinks } from "./FooterLinks";
import { SocialMediaLinks } from "./SocialMediaLinks";

const logoHeight = 60;

function FooterSection() {
  const { innerWidth: width } = window;
  const TABLET_WIDTH = 870;
  return (
    <div className="flex flex-column align-center footer-section">
      <div className="flex justify-space-between footer-components">
        {width > TABLET_WIDTH ? (
          <div className="footer-logo">
            <img
              src={AvocadoDAOLogo}
              alt="Avocado DAO logo"
              height={logoHeight}
            />
          </div>
        ) : null}
        <FooterLinks />
        {width <= TABLET_WIDTH ? (
          <div className="footer-logo">
            <img
              src={AvocadoDAOLogo}
              alt="Avocado DAO logo"
              height={logoHeight}
            />
          </div>
        ) : null}
        <SocialMediaLinks />
      </div>
      <CopyrightSection />
    </div>
  );
}

export { FooterSection };
