//import React from 'react';
import { useContext } from 'react';
//import { AuctionButtons } from 'components';
import { StoreContext } from 'index';
import { VideoModal } from './VideoModal';

import play_button from 'assets/images/play-button.png';
import video_placeholder from 'assets/images/video-placeholder-2.png';
import video_placeholder_2 from 'assets/images/video-placeholder-3.png';

const MOBILE_WIDTH = 480;
const IPAD_WIDTH = 1024;
const DESKTOP_WIDTH = 2400;

// const videoList = [
//     {
//         name: "AVOCADO Trailer",
//         LinktoVideo: "https://www.youtube.com/watch?v=6Y0hSB1zhEU",
//     },
//     {
//         name: "AVOCADO Guild",
//         LinktoVideo: "https://www.youtube.com/watch?v=pyre_g2bHhk",
//     },
// ];

// const linkToVideo = "https://www.youtube.com/watch?v=6Y0hSB1zhEU";
// const linkToVideo2 = "https://www.youtube.com/watch?v=pyre_g2bHhk";

const linkToToken =
    '#';

export default function VideoSection() {

    const store = useContext(StoreContext);

    return (

        <div className="video-section">
                <div className="video-list">
                    <div className='video-list-placeholder'>
                        <img
                        src={video_placeholder}
                        alt='video placeholder'
                        className='video-placeholder-img'
                        />
                        <div
                        onClick={() => {
                            store?.appStore.setShowVideoModal(true);
                            store?.appStore.setVideoUrl('https://www.youtube.com/watch?v=6Y0hSB1zhEU');
                        }
                        }
                        style={{ cursor: 'pointer' }}
                        >
                        <div className='play-list-button'>
                            <img src={play_button} alt='play-button' />
                        </div>
                        </div>
                    </div>       
                    <div className='video-list-placeholder-2'>
                        <img
                        src={video_placeholder_2}
                        alt='video placeholder'
                        className='video-placeholder-img'
                        />
                        <div
                        onClick={() => {
                            store?.appStore.setShowVideoModal(true);
                            store?.appStore.setVideoUrl('https://www.youtube.com/watch?v=pyre_g2bHhk');
                        }}
                        style={{ cursor: 'pointer' }}
                        >
                        <div className='play-list-button'>
                            <img src={play_button} alt='play-button' />
                        </div>
                        </div>
                        <VideoModal url={""} />
                    </div>                                   
                </div>
        </div>
    );
}
