import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";

import {
  MainSection,
  VaultsSection,
  GraphSection,
  PartnersSection,
  TransmissionsSection,
  GamesSection,
  GuildSection,
  VideoModal,
  HeaderMainSection,
  MetaverseSection,
} from "components";

import BottomSectionBgDesktop from "assets/images/bottom-section-background.svg";
import BottomSectionBgMobile from "assets/images/bottom-section-bg-mobile.svg";
import Layer1 from "assets/images/Layer_1@2x.png";
import Layer2 from "assets/images/Layer_2@2x.png";
import Layer3 from "assets/images/Layer_3@2x.png";
import Layer4 from "assets/images/Layer_4@2x.png";
import Layer5 from "assets/images/Layer_5@2x.png";
import Layer6 from "assets/images/Layer_6@2x.png";
import Layer7 from "assets/images/Layer_7@2x.png";
import Layer8 from "assets/images/Layer_8@2x.png";
import MobileBackground from "assets/images/Mobile_Background.png";
import NewsSection from "components/NewsSection";
import VideoSection from "components/VideoSection";
import CertikLogo from 'assets/images/icons/certik-logo.png';
import "./LandingPage.css";

type Props = {};

// TODO: change this flag
const linkToVideo = "https://www.youtube.com/watch?v=6Y0hSB1zhEU";
const linkToVideo2 = "https://www.youtube.com/watch?v=pyre_g2bHhk";

export const LandingPage = observer(({}: Props) => {
  const { innerWidth: width } = window;
  const layer1 = useRef<HTMLImageElement>(null);
  const layer2 = useRef<HTMLImageElement>(null);
  const layer3 = useRef<HTMLImageElement>(null);
  const layer4 = useRef<HTMLImageElement>(null);
  const layer5 = useRef<HTMLImageElement>(null);
  const layer6 = useRef<HTMLImageElement>(null);
  const layer7 = useRef<HTMLImageElement>(null);
  const layer8 = useRef<HTMLImageElement>(null);
  const middleSectionRef = useRef<HTMLDivElement>(null);

  const TABLET_WIDTH = 768;
  const TABLET_WIDTH_2 = 1024;
  const MOBILE_WIDTH = 468;

  const [emailValue, setEmailValue] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };

  const handleParallax = () => {
    const value = window.scrollY;
    if (layer2.current !== null) {
      layer2.current.style.top = value * 0.2 + "px";
    }
    if (layer3.current !== null) {
      layer3.current.style.top = value * 0.25 + "px";
    }
    if (layer4.current !== null) {
      layer4.current.style.top = value * 0.3 + "px";
    }
    if (layer5.current !== null) {
      layer5.current.style.top = value * 0.4 + "px";
    }
    if (layer6.current !== null) {
      layer6.current.style.top = value * 0.5 + "px";
    }
    if (layer7.current !== null) {
      layer7.current.style.top = value * 0.6 + "px";
    }
    if (layer8.current !== null) {
      layer8.current.style.top = value * 0.7 + "px";
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleParallax);

    // cleanup this component
    return () => {
      window.removeEventListener("scroll", handleParallax);
    };
  }, []);

  return (
    <div className="main">
      {width > MOBILE_WIDTH ? (
        <div
          className="parallax-wrap"
          style={{
            height: width < MOBILE_WIDTH ? "580px" : "calc(100vw / 1.40625)",
          }}
        >
          <img
            src={Layer8}
            ref={layer8}
            className="parallax-img behind"
            alt="parallax_layer"
          />
          <img
            src={Layer7}
            ref={layer7}
            className="parallax-img behind"
            alt="parallax_layer"
          />
          <img
            src={Layer6}
            ref={layer6}
            className={
              width < TABLET_WIDTH_2 ? "parallax-img behind" : "parallax-img"
            }
            alt="parallax_layer"
          />
          <img
            src={Layer5}
            ref={layer5}
            className={
              width < TABLET_WIDTH_2 ? "parallax-img behind" : "parallax-img"
            }
            alt="parallax_layer"
          />
          <img
            src={Layer4}
            ref={layer4}
            className={
              width < TABLET_WIDTH_2 ? "parallax-img behind" : "parallax-img"
            }
            alt="parallax_layer"
          />
          <img
            src={Layer3}
            ref={layer3}
            className={
              width < TABLET_WIDTH_2 ? "parallax-img behind" : "parallax-img"
            }
            alt="parallax_layer"
          />
          <img
            src={Layer2}
            ref={layer2}
            className={
              width < TABLET_WIDTH_2 ? "parallax-img behind" : "parallax-img"
            }
            alt="parallax_layer"
          />
          <img
            src={Layer1}
            ref={layer1}
            className="parallax-img"
            alt="parallax_layer"
          />
        </div>
      ) : (
        <img src={MobileBackground} alt="bg" className="mobile-img" />
      )}
      <HeaderMainSection />
      <MainSection />
      {/* <div
        className="space-placeholder"
        // style={{ height: "30vh", position: "relative" }}
      /> */}

      {/* <div className="audit-section">
        <a href="#">
          <span style={{color: "white"}}>AUDITED BY</span><br />
          <img src={CertikLogo} alt="audit-logo" />
        </a>
      </div> */}

      <div
        className={"content token-sale-content flex flex-column middle-section"}
        ref={middleSectionRef}
      >
        {/* <GuildSection /> */}
        {/* <GraphSection /> */}
        {/* <GamesSection /> */}
        <VaultsSection />
        <VideoSection />
        {/* <GamesSection /> */}
        <NewsSection />
      </div>
      <div className="bottom-section">
        <img
          src={
            width > TABLET_WIDTH
              ? BottomSectionBgDesktop
              : BottomSectionBgMobile
          }
          alt="Bottom section background"
          className={
            width > TABLET_WIDTH
              ? "bottom-section-background"
              : "bottom-section-background-mobile"
          }
        />
        <MetaverseSection />
        <PartnersSection />
        <TransmissionsSection
          onChangeValue={handleChange}
          inputValue={emailValue}
        />
      </div>
    </div>
  );
});
