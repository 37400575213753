import { ContractType } from "data/store/TokenStore";

const AXS_ABI = [
  {
    constant: true,
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

export const AXS_CONTRACT: ContractType = {
  address: "0xBB0E17EF65F82Ab018d8EDd776e8DD940327B28b",
  unit: "ether",
  abi: AXS_ABI,
};
