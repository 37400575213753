import { ContractType } from "data/store/TokenStore";

const PATH_ABI = [
    {
        constant: true,
        inputs: [
            {
                name: "_owner",
                type: "address"
            }
        ],
        name: "balanceOf",
        outputs: [
            {
                name: "balance",
                type: "uint256"
            }
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
];

export const PATH_CONTRACT: ContractType = {
  address: "0x2a2550e0a75acec6d811ae3930732f7f3ad67588",
  unit: "ether",
  abi: PATH_ABI,
};
