import React from 'react';
import { observer } from 'mobx-react-lite';
import './Bridge.css';
import BridgeTransferWidget from './cBridgeTransferWidget';
// import store from "../../../redux/store";
// import { Provider } from "react-redux";

type Props = {};

export const Bridge = observer(({}: Props) => {
  return (
    <div className="main-bridge">
      <div className="content">
        <BridgeTransferWidget />
      </div>
    </div>

    // <div className='App'>
    //   <Provider store={store}>
    //       <BridgeTransferWidget />
    //     </Provider> 
    // </div>
  );
});  
