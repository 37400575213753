import { ContractType } from "data/store/TokenStore";

const USDC_ABI = [
    {
        constant: true,
        inputs: [
            {
                name: "_owner",
                type: "address"
            }
        ],
        name: "balanceOf",
        outputs: [
            {
                name: "balance",
                type: "uint256"
            }
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
];

export const USDC_CONTRACT: ContractType = {
  address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  unit: "mwei",
  abi: USDC_ABI,
};
