import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';

export class AppStore {
  rootStore: RootStore;
  accounts: string[];
  showVideoModal: boolean;
  showSidebar: boolean;
  VideoUrl:string;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    // initiate rootStore if we need to access other mobx stores from here
    this.rootStore = rootStore;
    this.accounts = [];
    this.showVideoModal = false;
    this.showSidebar = false;
    this.VideoUrl = '';
  }

  getAccounts() {
    return this.accounts;
  }

  setAccounts(fetchedAccounts: string[]) {
    return (this.accounts = fetchedAccounts);
  }

  setShowVideoModal(state: boolean) {
    return (this.showVideoModal = state);
  }
  
  setShowSidebar(state: boolean) {
    return (this.showSidebar = state);
  }

  setVideoUrl(state: string) {
    return (this.VideoUrl = state);
  }

}
