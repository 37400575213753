import { ContractType } from "data/store/TokenStore";

const ILV_ABI = [
    {
        constant: true,
        inputs: [
            {
                name: "_owner",
                type: "address"
            }
        ],
        name: "balanceOf",
        outputs: [
            {
                name: "balance",
                type: "uint256"
            }
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
];

export const ILV_CONTRACT: ContractType = {
  address: "0x767fe9edc9e0df98e07454847909b5e959d7ca0e",
  unit: "ether",
  abi: ILV_ABI,
};
