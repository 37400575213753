import MainLogo from "assets/images/logos/avo-full-logo.png";

import HomeIcon from "assets/images/icons/navbar/home.svg";
import ProfileIcon from "assets/images/icons/navbar/profile.svg";
import BackIcon from "assets/images/icons/navbar/back-to-home.svg";

import SelectedHomeIcon from "assets/images/icons/navbar/home-selected.svg";
import { AccountDisplay } from "components";

type NavbarItemProps = {
  link: string;
  itemName: string;
  isCurrentlySelected?: boolean;
};

function NavbarItem({
  link,
  itemName,
  isCurrentlySelected = false,
}: NavbarItemProps) {
  let icon;
  switch (itemName) {
    case "Dashboard":
      icon = isCurrentlySelected ? SelectedHomeIcon : HomeIcon;
      break;
    case "Coming soon":
      icon = isCurrentlySelected ? ProfileIcon : ProfileIcon;
      break;
    default:
      icon = ProfileIcon;
  }
  return (
    <a
      href={link}
      className={`flex align-center ${
        isCurrentlySelected ? "selected-navbar-item" : "navbar-item"
      }`}
    >
      <img src={icon} alt={itemName} className="navbar-item-icon" />
      <p
        className={`body${
          isCurrentlySelected ? " selected-navbar-item-text" : ""
        }`}
      >
        {itemName}
      </p>
    </a>
  );
}

type SidebarProps = {
  isOpen?: boolean;
  connectedAccount?: string;
  onPressBackToHome?: () => void;
};

function Sidebar({
  isOpen = false,
  connectedAccount = "",
  onPressBackToHome = () => {},
}: SidebarProps) {
  const currentPage = window.location.pathname;
  const { innerWidth: width } = window;
  // const TABLET_WIDTH = 768;
  const TABLET_WIDTH = 1024;

  return (
    <div
      className={`flex flex-column align-center sidebar${
        width <= TABLET_WIDTH && isOpen ? " sidebar-active" : ""
      }`}
    >
      {width > TABLET_WIDTH ? (
        <img src={MainLogo} alt="Avocado DAO Logo" className="sidebar-logo" />
      ) : null}
      <ul className="nav-list">
        <li>
          <NavbarItem
            link="/"
            itemName="Dashboard"
            isCurrentlySelected={currentPage === "/app" || currentPage === "/"}
          />
        </li>
        <li>
          <NavbarItem
            link="/"
            itemName="Coming soon"
            isCurrentlySelected={currentPage === "Link coming soon"}
          />
        </li>
      </ul>
      <div className="flex flex-column sidebar-bottom-section">
        {width <= TABLET_WIDTH && !!connectedAccount ? (
          <div style={{ marginBottom: 30 }}>
            <AccountDisplay connectedAccount={connectedAccount} />
          </div>
        ) : null}
        {/* {width <= TABLET_WIDTH ? (
          <button className="white-paper-btn">
            <p className="body">WHITEPAPER</p>
          </button>
        ) : null} */}
        <button className="back-to-home-btn" onClick={onPressBackToHome}>
          <img
            src={BackIcon}
            alt="Back to home"
            className="back-to-home-icon"
          />
          <p className="headline back-to-home-text">Back to Home</p>
        </button>
      </div>
    </div>
  );
}

export { Sidebar };
