// @ts-nocheck
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { VaultBlock } from "./VaultBlock";
import { AuctionButtons } from 'components';

const linkToToken = 'https://staking.avocadodao.io';

function VaultsSection() {
    const [vaults, setVaults] = useState([]);

    const getVaults = async () => {
        try {
            let response = await axios.get('https://staking.avocadodao.io/api/pools');
            setVaults(response.data.slice(0, 4).sort((prev, next) => {
                return new Date(next.created_at) - new Date(prev.created_at);
            }));
        } catch (e) {
            console.log(e.message);
        }
    }

    useEffect(() => {
        getVaults();
    }, []);

    return (
        <div className="vaults-section flex flex-column">
            <div className="flex flex-column align-center">
                <div className="section-title partners-heading justify-center">Maximize Rewards by staking AVG Tokens</div>
                <div className="text-wrap">
                    <p>
                        Welcome to the Avocado DAO staking protocol. Here you are able to benefit from staking tokens and receiving AVG rewards.
                    </p>
                </div>
            </div>
            <div className="container">
                <div className="vault-list flex flex-row">
                    {vaults.map((element, index) => {
                        return <VaultBlock key={index} index={index + 1} vault={element} />;
                    })}
                </div>
                <div className="all-vaults-btn">
                    <AuctionButtons
                        // leftBtnTitle='Read White Paper'
                        rightBtnTitle='View All Vaults'
                        // onPressLeftBtn={() =>
                        //   window.open(linkToWhitePaper, '_blank')?.focus()
                        // }
                        onPressRightBtnLink={linkToToken}
                    />
                </div>
            </div>
        </div>
    );
}

export { VaultsSection };