import { IPartnerIcon } from "../type";

// Partners Logo
import Partners1 from "assets/images/metaverse/partners/filtered/partner-1-cyball.png";
import Partners2 from "assets/images/metaverse/partners/filtered/partner-2-axie.png";
import Partners3 from "assets/images/metaverse/partners/filtered/partner-3-karmaverse.png";
import Partners4 from "assets/images/metaverse/partners/filtered/partner-4-wasted-lands.jpeg";//.png";
import Partners5 from "assets/images/metaverse/partners/filtered/partner-5-puzzle-royale.png";
import Partners6 from "assets/images/metaverse/partners/filtered/partner-6-league-of-kingdoms.png";
import Partners7 from "assets/images/metaverse/partners/filtered/partner-7-sipher.png";
import Partners8 from "assets/images/metaverse/partners/filtered/partner-8-bigtime.png";
import Partners9 from "assets/images/metaverse/partners/filtered/partner-9-mavia.png";
import Partners10 from "assets/images/metaverse/partners/filtered/partners-10-genopets.png";//.svg";

import Partners11 from "assets/images/metaverse/partners/filtered/partner-11-magic-eden.png";
import Partners12 from "assets/images/metaverse/partners/filtered/partner-afkdao.png";
import Partners13 from "assets/images/metaverse/partners/filtered/partner-apeiron.png";
import Partners14 from "assets/images/metaverse/partners/filtered/partner-aradena.png";
import Partners15 from "assets/images/metaverse/partners/filtered/partner-arcade-galaxy.png";
import Partners16 from "assets/images/metaverse/partners/filtered/partner-ascenders.png";
import Partners17 from "assets/images/metaverse/partners/filtered/partner-blast-royal.png";
import Partners18 from "assets/images/metaverse/partners/filtered/partner-blocklords.png";
import Partners19 from "assets/images/metaverse/partners/filtered/partner-chibi-clash.png";
import Partners20 from "assets/images/metaverse/partners/filtered/partner-chill-chat.png";

import Partners21 from "assets/images/metaverse/partners/filtered/partner-darley-go.png";
import Partners22 from "assets/images/metaverse/partners/filtered/partner-derby-stars.png";
import Partners23 from "assets/images/metaverse/partners/filtered/partner-dropp-2.jpg";
import Partners24 from "assets/images/metaverse/partners/filtered/partner-drunk-robots.png";
import Partners25 from "assets/images/metaverse/partners/filtered/partner-eizper.png";
import Partners26 from "assets/images/metaverse/partners/filtered/partner-elpis.png";
import Partners27 from "assets/images/metaverse/partners/filtered/partner-gfc.png";
import Partners28 from "assets/images/metaverse/partners/filtered/partner-heroes-chained.png";
import Partners29 from "assets/images/metaverse/partners/filtered/partner-ignite-tourna.png";
import Partners30 from "assets/images/metaverse/partners/filtered/partner-jambo.png";

import Partners31 from "assets/images/metaverse/partners/filtered/partner-kingdom-x.jpg";
import Partners32 from "assets/images/metaverse/partners/filtered/partner-l2y.jpg";
import Partners33 from "assets/images/metaverse/partners/filtered/partner-lootex.png";
import Partners34 from "assets/images/metaverse/partners/filtered/partner-meta-gods.png";
import Partners35 from "assets/images/metaverse/partners/filtered/partner-metal-core.png";
import Partners36 from "assets/images/metaverse/partners/filtered/partner-mirrorworld.png";
import Partners37 from "assets/images/metaverse/partners/filtered/partner-mixmob.png";
import Partners38 from "assets/images/metaverse/partners/filtered/partner-monkey-league.png";
import Partners39 from "assets/images/metaverse/partners/filtered/partner-pegaxy.jpg";
import Partners40 from "assets/images/metaverse/partners/filtered/partner-phantom-galaxies.png";

import Partners41 from "assets/images/metaverse/partners/filtered/partner-planet-mojo.png";
import Partners42 from "assets/images/metaverse/partners/filtered/partner-rebel-bots.webp";
import Partners43 from "assets/images/metaverse/partners/filtered/partner-stella-fantasy.png";
import Partners44 from "assets/images/metaverse/partners/filtered/partner-the-killbox.png";


const PartnersAll: IPartnerIcon[] = [
  {
    icon: Partners1,
    name: "AFKDAO",
  },
  {
    icon: Partners2,
    name: "Apeiron",
  },
  {
    icon: Partners3,
    name: "Aradena",
  },
  {
    icon: Partners4,
    name: "Arcade Galaxy",
  },
  {
    icon: Partners5,
    name: "Ascenders",
  },
  {
    icon: Partners6,
    name: "Axie Infinity",
  },
  {
    icon: Partners7,
    name: "Bigtime",
  },
  {
    icon: Partners8,
    name: "Blast Royale",
  },
  {
    icon: Partners9,
    name: "Blocklords",
  },
  {
    icon: Partners10,
    name: "Chibi Clash",
  },
  {
    icon: Partners11,
    name: "ChillChat",
  },
  {
    icon: Partners12,
    name: "CyBall",
  },
  {
    icon: Partners13,
    name: "Darley Go",
  },
  {
    icon: Partners14,
    name: "Derby Stars",
  },
  {
    icon: Partners15,
    name: "Dropp",
  },
  {
    icon: Partners16,
    name: "Drunk Robots",
  },
  {
    icon: Partners17,
    name: "Eizper",
  },
  {
    icon: Partners18,
    name: "Elpis Battle",
  },
  {
    icon: Partners19,
    name: "Galaxy Fight Club",
  },
  {
    icon: Partners20,
    name: "Heroes Chained",
  },
  {
    icon: Partners21,
    name: "Ignite Tournaments",
  },
  {
    icon: Partners22,
    name: "JAMBO",
  },
  {
    icon: Partners23,
    name: "Karmaverse",
  },
  {
    icon: Partners24,
    name: "KingdomX",
  },
  {
    icon: Partners25,
    name: "L2Y (De Game)",
  },
  {
    icon: Partners26,
    name: "League Of Kingdoms",
  },
  {
    icon: Partners27,
    name: "Lootex",
  },
  {
    icon: Partners28,
    name: "Magic Eden",
  },
  {
    icon: Partners29,
    name: "Mavia",
  },
  {
    icon: Partners30,
    name: "Meta Gods",
  },
  {
    icon: Partners31,
    name: "MetalCore",
  },
  {
    icon: Partners32,
    name: "Mirror World",
  },
  {
    icon: Partners33,
    name: "MixMob",
  },
  {
    icon: Partners34,
    name: "Monkey League",
  },
  {
    icon: Partners35,
    name: "Pegaxy",
  },
  {
    icon: Partners36,
    name: "Phantom Galaxies",
  },
  {
    icon: Partners37,
    name: "Planet Mojo",
  },
  {
    icon: Partners38,
    name: "Puzzle Royale",
  },
  {
    icon: Partners39,
    name: "Rebel Bots",
  },
  {
    icon: Partners40,
    name: "Sipher",
  },
  {
    icon: Partners41,
    name: "Stella Fantasy",
  },
  {
    icon: Partners42,
    name: "The Killbox",
  },
  {
    icon: Partners43,
    name: "Wasted Lands",
  },
];

const PartnersFilteredAll: IPartnerIcon[] = [
  {
    icon: Partners1,
    name: "Cyball",
  },
  {
    icon: Partners2,
    name: "Axie",
  },
  {
    icon: Partners3,
    name: "Karmaverse",
  },
  {
    icon: Partners4,
    name: "Waster Lands",
  },
  {
    icon: Partners5,
    name: "Puzzle Royale",
  },
  {
    icon: Partners6,
    name: "League of Kingdoms",
  },
  {
    icon: Partners7,
    name: "Sipher",
  },
  {
    icon: Partners8,
    name: "Bigtime",
  },
  {
    icon: Partners9,
    name: "Mavia",
  },
  {
    icon: Partners10,
    name: "Genopets",
  },
  {
    icon: Partners11,
    name: "Magic Eden",
  },
  {
    icon: Partners12,
    name: "AFKDAO",
  },
  {
    icon: Partners13,
    name: "Apeiron",
  },
  {
    icon: Partners14,
    name: "Aradena",
  },
  {
    icon: Partners15,
    name: "Arcade Galaxy",
  },
  {
    icon: Partners16,
    name: "Ascender",
  },
  {
    icon: Partners17,
    name: "Blast Royal",
  },
  {
    icon: Partners18,
    name: "Blocklords",
  },
  {
    icon: Partners19,
    name: "Chibi Clash",
  },
  {
    icon: Partners20,
    name: "Chill Chat",
  },
  {
    icon: Partners21,
    name: "Darley Go",
  },
  {
    icon: Partners22,
    name: "Derby Stars",
  },
  {
    icon: Partners23,
    name: "Dropp",
  },
  {
    icon: Partners24,
    name: "Drunk Robots",
  },
  {
    icon: Partners25,
    name: "Eizper",
  },
  {
    icon: Partners26,
    name: "Elpis",
  },
  {
    icon: Partners27,
    name: "GFC",
  },
  {
    icon: Partners28,
    name: "Heroes Chained",
  },
  {
    icon: Partners29,
    name: "Ignite Tourna",
  },
  {
    icon: Partners30,
    name: "Jambo",
  },
  {
    icon: Partners31,
    name: "Kingdom X",
  },
  {
    icon: Partners32,
    name: "L2Y",
  },
  {
    icon: Partners33,
    name: "Lootex",
  },
  {
    icon: Partners34,
    name: "Meta Gods",
  },
  {
    icon: Partners35,
    name: "Metal Core",
  },
  {
    icon: Partners36,
    name: "Mirrorworld",
  },
  {
    icon: Partners37,
    name: "Mixmob",
  },
  {
    icon: Partners38,
    name: "Monkey League",
  },
  {
    icon: Partners39,
    name: "Pegaxy",
  },
  {
    icon: Partners40,
    name: "Phantom Galaxies",
  },
  {
    icon: Partners41,
    name: "Planet Mojo",
  },
  {
    icon: Partners42,
    name: "Rebel Bots",
  },
  {
    icon: Partners43,
    name: "Stella Fantasy",
  },
  {
    icon: Partners44,
    name: "The Killbox",
  },
]

export { PartnersAll, PartnersFilteredAll };

export { default as ArrowRight } from "assets/images/icon-right-arrow.svg";
export { default as HexagonOff } from "assets/images/honeycomb/hexagon-off.svg";
export { default as HexagonOn } from "assets/images/honeycomb/hexagon-on.svg";
export { default as HexagonOnHover } from "assets/images/honeycomb/hexagon-on-2--hover.svg";
