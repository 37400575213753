import { ContractType } from "data/store/TokenStore";

const AAG_ABI = [
    {
        constant: true,
        inputs: [
            {
                name: "_owner",
                type: "address"
            }
        ],
        name: "balanceOf",
        outputs: [
            {
                name: "balance",
                type: "uint256"
            }
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
];

export const AAG_CONTRACT: ContractType = {
  address: "0x5ba19d656b65f1684cfea4af428c23b9f3628f97",
  unit: "ether",
  abi: AAG_ABI,
};
