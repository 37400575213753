import { useContext, useRef } from 'react';
import { StoreContext } from 'index';
import Modal from 'react-modal';
import Cancel from 'assets/images/icons/cross.svg';
import { useWeb3Context } from "../providers/Web3ContextProvider";
import Onboarding from '@metamask/onboarding';
import MetaMaskIcon from 'assets/images/icons/meta-mask.svg';
import WalletConnectIcon from 'assets/images/icons/connect.png';

Modal.setAppElement('#root');

type Props = {
  isOpen: boolean;
  closeModal: () => void | Promise<void>;
  onAfterOpen?: () => void | Promise<void>;
  connectMetaMask?: () => void | Promise<void>;
  width: number;
  // wallets: Array<any>;
  className?: string;
};

export const WalletModal = ({
  isOpen,
  onAfterOpen,
  closeModal,
  connectMetaMask,
  width,
  // wallets,
  className = '',
}: Props) => {
  const MOBILE_WIDTH = 468;
  const store = useContext(StoreContext);
  const { loadWeb3Modal } = useWeb3Context();
  const { ethereum } = window;
  const onboarding = useRef<Onboarding>();
  const isMobile = width < 768;

  const connectMetamask = async () => {
    try {
      const response = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      store?.appStore.setAccounts(response);
      await loadWeb3Modal("injected", undefined);
    } catch (error) {
      if (error.code === -32002) {
        alert('Please continue in the MetaMask extension');
      }
      console.log('error', error);
    }
  };

  const installMetaMask = () => {
    onboarding.current?.startOnboarding();
  };

  const connectWalletConnect = async () => {
    try {
      await loadWeb3Modal("walletconnect", isMobile ? () => true : undefined);
    } catch (error) {
      console.error('error', error);
    }
  };

  // TODO: add more wallets
  const wallets = [
    {
      title:
        ethereum && ethereum.isMetaMask
          ? 'Metamask'
          : 'Install MetaMask',
      name: 'MetaMask',
      icon: MetaMaskIcon,
      onClick: () => {
        closeModal();
        if (ethereum && ethereum.isMetaMask) {
          connectMetamask();
        } else {
          installMetaMask();
        }
      },
    },
    {
      title: 'WalletConnect',
      name: 'WalletConnect',
      icon: WalletConnectIcon,
      onClick: () => {
        closeModal();
        connectWalletConnect();
      },
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={closeModal}
      contentLabel='Example Modal'
      className={'Modal ' + className}
      style={{
        overlay: {
          position: 'fixed',
          height: '100%',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 20000,
          alignItems: 'center',
          justifyContent: 'center',
        },
        content: {
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          width: width > MOBILE_WIDTH ? '30vw' : '80vw',
          alignSelf: 'center',
          justifyContent: 'center',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
          margin: 'auto',
          overflow: 'auto',
          borderWidth: 0,
          borderRadius: '24px',
          outline: 'none',
          padding: '24px 24px 32px',
          backgroundColor: '#2A232F',
        },
      }}
    >
      <div>
        <div className='flex flex-row justify-space-between modal-header'>
          <div className='title-sm'>Connect Your Wallet</div>
          <img
            className='cancel-icon'
            src={Cancel}
            alt='Cancel Icon'
            onClick={closeModal}
          />
        </div>
        {/* Wallet list */}
        {wallets.map((wallet, index) => (
          <div
            className='flex flex-row align-start wallet spacing'
            onClick={wallet.onClick}
            key={index}
          >
            <img className='icon' style={{width: '5%'}} src={wallet.icon} alt='MetaMask Icon' />
            <div className='title-sm'>{wallet.title}</div>
          </div>
        ))}
        <p className='more-wallets'>More wallets coming soon</p>
      </div>
    </Modal>
  );
};
