import { ContractType } from "data/store/TokenStore";

const REVV_ABI = [
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
];

export const REVV_CONTRACT: ContractType = {
  address: "0x557B933a7C2c45672B610F8954A3deB39a51A8Ca",
  unit: "ether",
  abi: REVV_ABI,
};
