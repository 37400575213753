import React, { useEffect, useMemo, useState } from "react";

import {
  PartnersFilteredAll,
  HexagonOff,
  HexagonOn,
  HexagonOnHover,
  ArrowRight,
} from "./icons";
import { IPartnerIcon } from "./type";

const MetaverseDesktopSection = () => {
  const partners: IPartnerIcon[] = useMemo(() => PartnersFilteredAll, []);

  const [page, setPage] = useState(1);
  const [partnersPaginated, setPartnersPaginated] = useState<IPartnerIcon[]>(
    []
  );

  // min page const
  const MIN_PAGE = 1;
  const TOTAL_PARTNERS_SHOW = 16;
  const MAX_PAGE = Math.ceil(partners.length / TOTAL_PARTNERS_SHOW);

  useEffect(() => {
    // partners array loops for pagination usage
    let start = (page - 1) * TOTAL_PARTNERS_SHOW;
    let end = page * TOTAL_PARTNERS_SHOW;

    setPartnersPaginated(partners.slice(start, end));
  }, [page, partners]);

  // handle pagination previous and next
  const handlePrevPage = () => {
    if (page === MIN_PAGE) return;

    setPage((page) => page - 1);
  };
  const handleNextPage = () => {
    if (page === MAX_PAGE) return;

    setPage((page) => page + 1);
  };

  const renderHexagonOff = (loopCount: number = 0) => {
    if (loopCount === 0) {
      return (
        <li
          key={`hexagon-off-single-${Math.random()}`}
          className="honeycomb-cell"
        >
          <img alt="img" className="honeycomb-cell_img" src={HexagonOff} />
        </li>
      );
    }

    const hexagons = [];
    for (let i = 0; i < loopCount; i++) {
      hexagons.push(
        <li key={`hexagon-off-${i}`} className="honeycomb-cell">
          <img alt="img" className="honeycomb-cell_img" src={HexagonOff} />
        </li>
      );
    }
    return hexagons;
  };
  const renderHexagonOn = (partner: string, name: string) => {
    return (
      <li key={`hexagon-on-${name}`} className="honeycomb-cell">
        <img alt="img" className="honeycomb-cell_img" src={HexagonOn} />
        <img alt="img" className="honeycomb-img-hover" src={HexagonOnHover} />
        <span>{name}</span>

        <div className="honeycomb-img-wrapper" style={{ zIndex: 5 }}>
          <img alt="img" className="honeycomb-img-partner" src={partner} />
        </div>
      </li>
    );
  };

  const renderArrowPrev = () => {
    return (
      <li className="honeycomb-cell">
        <img alt="img" className="honeycomb-cell_img" src={HexagonOff} />
        <div
          className="honeycomb-img-arrow-wrapper honeycomb-img-arrow-wrapper--arrow-left"
          style={{ zIndex: 5 }}
          onClick={() => {
            handlePrevPage();
          }}
        >
          <img
            alt="img"
            className={`honeycomb-img-arrow ${
              page === MIN_PAGE ? "honeycomb-img-arrow--inactive" : ""
            }`}
            src={ArrowRight}
          />
        </div>
      </li>
    );
  };
  const renderArrowNext = () => {
    return (
      <li className="honeycomb-cell">
        <img alt="img" className="honeycomb-cell_img" src={HexagonOff} />
        <div
          className="honeycomb-img-arrow-wrapper"
          style={{ zIndex: 5 }}
          onClick={() => {
            handleNextPage();
          }}
        >
          <img
            alt="img"
            className={`honeycomb-img-arrow ${
              page === MAX_PAGE ? "honeycomb-img-arrow--inactive" : ""
            }`}
            src={ArrowRight}
          />
        </div>
      </li>
    );
  };

  const renderPartners = (start: number, end: number) => {
    const partnersLoop = [];
    for (let i = start; i < end; i++) {
      if (partnersPaginated[i]) {
        partnersLoop.push(
          renderHexagonOn(partnersPaginated[i].icon, partnersPaginated[i].name)
        );
      } else {
        partnersLoop.push(renderHexagonOff());
      }
    }
    return partnersLoop;
  };

  const renderPartnerRow1 = () => {
    return (
      <div className="honeycomb honeycomb-odd">
        {renderHexagonOff(18)}
        {renderPartners(0, 5)}
        {renderHexagonOff(18)}
      </div>
    );
  };

  const renderPartnerRow2 = () => {
    return (
      <div className="honeycomb honeycomb-even">
        {renderHexagonOff(18)}
        {renderArrowPrev()}
        {renderPartners(5, 11)}
        {renderArrowNext()}
        {renderHexagonOff(18)}
      </div>
    );
  };

  const renderPartnerRow3 = () => {
    return (
      <div className="honeycomb honeycomb-odd honeycomb-odd--center">
        {renderHexagonOff(18)}
        {/* {renderArrowPrev()} */}
        {renderPartners(11, 16)}
        {/* {renderArrowNext()} */}
        {renderHexagonOff(18)}
      </div>
    );
  };

  const renderPartnerRow4 = () => {
    return (
      <div className="honeycomb honeycomb-even">
        {renderHexagonOff(18)}
        {renderPartners(21, 28)}
        {renderHexagonOff(18)}
      </div>
    );
  };

  const renderPartnerRow5 = () => {
    return (
      <div className="honeycomb honeycomb-odd">
        {renderHexagonOff(18)}
        {renderPartners(28, 34)}
        {renderHexagonOff(18)}
      </div>
    );
  };

  return (
    <ul id="metaverse-desktop" className="honeycomb">
      {/* Row 1 */}
      {renderPartnerRow1()}

      {/* Row 2 */}
      {renderPartnerRow2()}

      {/* Row 3 */}
      {renderPartnerRow3()}

      {/* Row 4 */}
      {/* {renderPartnerRow4()} */}

      {/* Row 5 */}
      {/* {renderPartnerRow5()} */}
    </ul>
  );
};

export default MetaverseDesktopSection;
