import { makeAutoObservable } from "mobx";

import { RootStore } from "./RootStore";

// Object representing a token-currency pair and its price

export type PriceType = {
  name: string;

  price: number;
};

export class PriceStore {
  rootStore: RootStore;

  latestPrices: PriceType[];

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);

    // initiate rootStore if we need to access other mobx stores from here

    this.rootStore = rootStore;

    this.latestPrices = [];
  }

  getLatestPrices() {
    return this.latestPrices;
  }

  updateLatestPrice(currencyPairName: string, newPrice: number) {
    console.log("Updating latest price of " + currencyPairName);

    this.latestPrices = [
      ...this.latestPrices.filter(
        (element) => element.name !== currencyPairName
      ),

      { name: currencyPairName, price: newPrice },
    ].sort((a: PriceType, b: PriceType) => {
      if (a.name < b.name) {
        return -1;
      }

      if (a.name > b.name) {
        return 1;
      }

      return 0;
    });

    return this.latestPrices;
  }

  getPriceOf(tokenName: string) {
    const tokenToFind = this.latestPrices.find(
      (price) => price.name === tokenName
    );
    if (tokenToFind) {
      return tokenToFind.price;
    }
  }
}
