import { ContractType } from "data/store/TokenStore";

const SLP_ABI = [
  {
    constant: true,
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

export const SLP_CONTRACT: ContractType = {
  address: "0xCC8Fa225D80b9c7D42F96e9570156c65D6cAAa25",
  unit: "wei",
  abi: SLP_ABI,
};
