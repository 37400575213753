import React, { CSSProperties } from "react";

function CopyrightSection() {
  const { innerWidth: width } = window;
  const TABLET_WIDTH = 870;
  return (
    <div className="flex copyright-section">
      {width > TABLET_WIDTH ? (
        <div className="flex copyright">
          <p className="footer">©{ new Date().getFullYear() } Avocado Dao. All rights reserved</p>
        </div>
      ) : null}
      <div className="flex liability">
        <p className="footer-small">
          This website is maintained by Avocado Dao. The contents and opinions
          of this website are those of Avocado Dao. Avocado Dao provides
          links to the cryptocurrency exchanges as a service to the public.
          Information provided by these websites are not warranted by Avocado
          Dao to be correct, complete and up-to-date. Avocado Dao is not
          responsible for their content and expressly rejects and liability for
          damages of any kind resulting from the use, reference to, or reliance
          on any information contained within these websites.
        </p>
      </div>
      {width <= TABLET_WIDTH ? (
        <div className="flex copyright">
          <p className="footer">©{ new Date().getFullYear() } Avocado Dao. All rights reserved</p>
        </div>
      ) : null}
    </div>
  );
}

export { CopyrightSection };
