// @ts-nocheck
import { useContext, useEffect, useRef, useState } from 'react';
import { StoreContext } from 'index';
import Onboarding from '@metamask/onboarding';
import MetaMaskOnboarding from '@metamask/onboarding';
import { Link } from 'react-router-dom';
import Logo from 'assets/images/logos/avo-full-logo.png';
import AppMenuIcon from 'assets/images/icons/navbar/hamburger.svg';
import CloseIcon from 'assets/images/icons/navbar/close.svg';
import { AccountDisplay, WalletModal, FullSidebar } from 'components';
import { useHistory } from 'react-router-dom';
import { HeaderDropdownMenu } from './HeaderDropdownMenu';
import TwitterIcon from 'assets/images/icons/twitter.svg';
import DiscordIcon from 'assets/images/icons/discord.svg';
import MediumIcon from 'assets/images/icons/medium.svg';
import TelegramIcon from 'assets/images/icons/telegram.svg';
import UniswapIcon from 'assets/images/icons/uniswap.svg';
import BitmartIcon from 'assets/images/icons/bitmart.svg';
import MEXCIcon from 'assets/images/icons/mexc.svg';
import NewsIcon from 'assets/images/icons/news.svg';
import PancakeSwapIcon from 'assets/images/icons/icon-cake-1.svg';
import Header from './Bridge/Header';
import { useLocation } from 'react-router-dom';
import { useWeb3Context } from "../providers/Web3ContextProvider";

type HeaderProps = {
  onPressMenu?: () => void;
  isNavbarOpen?: boolean;
  connectedAccount?: string;
  connectWalletCallback?: () => void;
};

const linkToWhitePaper = 'https://whitepaper.avocadodao.io/';

function AppHeader({
  onPressMenu = () => {},
  isNavbarOpen = false,
  connectedAccount = '',
  connectWalletCallback = () => {},
}: HeaderProps) {
  // const TABLET_WIDTH = 768;
  const TABLET_WIDTH = 1024;
  const store = useContext(StoreContext);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const { innerWidth: width } = window;
  const onboarding = useRef<Onboarding>();
  const location = useLocation();
  const { address, signer } = useWeb3Context();

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  function onAfterOpen() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  // If account not connected, show landing page app header
  return (
    <>
      <FullSidebar isOpen={isNavbarOpen} onPressConnect={openModal} />
      <header className='sticky-main-header'>
        <Link to='/'>
          <img
            src={Logo}
            alt='AVG_LOGO'
            width={width <= 480 ? 94 : 165}
            height={'auto'}
          />
        </Link>
        <div className='flex flex-row align-center header-content'>
          <div className='flex flex-row full-header-content'>
            <HeaderDropdownMenu
              title='Newsletter'
              items={headerItems.newsletter}
            />
            <a
              href={linkToWhitePaper}
              target='_blank'
              rel='noreferrer'
              style={{ textDecoration: 'none' }}
            >
              <button className='btn-text'>White Paper</button>
            </a>
            <div className='staking'>
              <Link to='/bridge'>
                <button className='btn-text'>Bridge</button>
              </Link>
            </div>
            <div className='staking'>
              <a href='https://staking.avocadodao.io' target="_blank">
                <button className='btn-text link'>Staking</button>
              </a>
            </div>
            <div className='staking'>
              <a href='https://rewards.avocadodao.io' target="_blank">
                <button className='btn-text link'>Vesting</button>
              </a>
            </div>
            <HeaderDropdownMenu title='Social' items={headerItems.social} />
            <HeaderDropdownMenu title='Buy AVG' items={headerItems.token} />
          </div>
          {location.pathname === '/bridge' && signer ? (
            <Header />
          ) : (
            ''
          )}
          <div className='mobile-header-content' style={{marginLeft: '15px'}}>
            <button onClick={onPressMenu} className='toggle-navbar-btn'>
              <img
                src={isNavbarOpen ? CloseIcon : AppMenuIcon}
                alt={isNavbarOpen ? 'Close menu' : 'Open menu'}
                style={{ marginRight: isNavbarOpen ? 3 : 0 }}
              />
            </button>
          </div>
          {!signer ? (
            <button
              className='btn-hero btn-connect btn-header'
              onClick={openModal}
            >
              Connect Wallet
            </button>
            ) : (
            <AccountDisplay connectedAccount={address} />
          )}
          
          {/* {signer && (
            <div>
              <div
                className={totalActionNum || totalPaddingNum ? classes.activeChainLocale : classes.chainLocale}
                onClick={() => {
                  handleOpenHistoryModal();
                }}
              >
                <div className={classes.historyText}>{getstatusText()}</div>
              </div>
            </div>
          )} */}
        </div>
        <WalletModal
          isOpen={isOpen}
          onAfterOpen={onAfterOpen}
          closeModal={closeModal}
          width={width}
        />
      </header>
    </>
  );

  // Otherwise, show connected account app header
  // return (
  //   <header
  //     className={`align-center main-header${
  //       width > TABLET_WIDTH ? ' justify-end' : ' justify-space-between'
  //     }`}
  //   >
  //     {width <= TABLET_WIDTH ? (
  //       <Link to='/'>
  //         <img
  //           src={Logo}
  //           alt='AVG_LOGO'
  //           width={width <= 480 ? 98 : 208}
  //           height={width <= 480 ? 35 : 75}
  //         />
  //       </Link>
  //     ) : null}
  //     {width > TABLET_WIDTH ? (
  //       <div className='flex flex-row align-center'>
  //         {/* <button className="btn-text header-white-paper">Whitepaper</button> */}

  //         <AccountDisplay connectedAccount={store?.appStore.getAccounts()[0]} />
  //       </div>
  //     ) : null}
  //     {width <= TABLET_WIDTH ? (
  //       <button onClick={onPressMenu} className='toggle-navbar-btn'>
  //         <img
  //           src={isNavbarOpen ? CloseIcon : AppMenuIcon}
  //           alt={isNavbarOpen ? 'Close menu' : 'Open menu'}
  //           style={{ marginRight: isNavbarOpen ? 3 : 0 }}
  //         />
  //       </button>
  //     ) : null}
  //   </header>
  // );
}

const headerItems = {
  social: [
    {
      title: 'Medium',
      icon: MediumIcon,
      href: 'https://avocadoguild.medium.com/',
    },
    {
      title: 'Discord',
      icon: DiscordIcon,
      href: 'https://discord.gg/avocadoguild',
    },
    {
      title: 'Twitter',
      icon: TwitterIcon,
      href: 'https://twitter.com/avocadoguild',
    },
    {
      title: 'Telegram',
      icon: TelegramIcon,
      href: 'https://t.me/avocado_guild',
    },
  ],
  token: [
    {
      title: 'Pancake Swap',
      icon: PancakeSwapIcon,
      href: 'https://pancakeswap.finance/swap?outputCurrency=0xa41f142b6eb2b164f8164cae0716892ce02f311f',
    },
    {
      title: 'Add Liquidity',
      icon: PancakeSwapIcon,
      href: 'https://pancakeswap.finance/add/0xa41F142b6eb2b164f8164CAE0716892Ce02f311f/BNB',
    },
    {
      title: 'UniSwap',
      icon: UniswapIcon,
      href: 'https://app.uniswap.org/#/swap?inputCurrency=0xa41f142b6eb2b164f8164cae0716892ce02f311f&outputCurrency=ETH',
    },
    {
      title: 'MEXC',
      icon: MEXCIcon,
      href: 'https://www.mexc.com/exchange/AVG_USDT',
    },
    {
      title: 'Bitmart',
      icon: BitmartIcon,
      href: 'https://www.bitmart.com/trade/en?symbol=AVG_USDT&layout=basic',
    },
  ],
  newsletter: [
    {
      title: 'Jun 22',
      icon: NewsIcon,
      href: 'https://heyzine.com/flip-book/746a31dde0.html',
    },
    {
      title: 'May 22',
      icon: NewsIcon,
      href: 'https://heyzine.com/flip-book/d72272fcbb.html',
    },
    {
      title: 'Apr 22',
      icon: NewsIcon,
      href: 'https://heyzine.com/flip-book/ab489828ff.html',
    },
    {
      title: 'Mar 22',
      icon: NewsIcon,
      href: 'https://heyzine.com/flip-book/579f684416.html',
    },
    {
      title: 'Feb 22',
      icon: NewsIcon,
      href: 'https://heyzine.com/flip-book/26855673d7.html',
    },
    {
      title: 'Jan 22',
      icon: NewsIcon,
      href: 'https://heyzine.com/flip-book/cc1e395774.html',
    },
    {
      title: 'Dec 21',
      icon: NewsIcon,
      href: 'https://heyzine.com/flip-book/9cbc873b6d.html#page/1',
    },
    {
      title: 'Nov 21',
      icon: NewsIcon,
      href: 'https://heyzine.com/flip-book/b0c1d4b973.html',
    },
  ],
};

export { AppHeader, headerItems };
