import React, { CSSProperties } from "react";
import Marquee from "react-fast-marquee";
import { PartnerType } from "./PartnersSection";

type Props = {
  partners?: PartnerType[];
};

type ItemProps = {
  partner: PartnerType;
};

function PartnerItem({ partner }: ItemProps) {
  return (
    <div className="flex justify-center align-center partner-logo-scroll">
      <img
        src={partner.logo}
        alt={partner.name}
        style={{
          maxHeight: 60,
          maxWidth: partner.name === "Three Arrows Capital" ? 220 : 140,
        }}
      />
    </div>
  );
}

function PartnersAutoScroll({ partners = [] }: Props) {
  const middleIndex = Math.floor(partners.length / 2);
  const partnersTop = partners
    .map((partner) => ({ ...partner }))
    .filter((partner, index) => index < middleIndex);
  const partnersBottom = partners
    .map((partner) => ({ ...partner }))
    .filter((partner, index) => index >= middleIndex);

  return (
    <>
      <Marquee gradient={false} className="partners-marquee">
        {partnersTop.map((element, index) => {
          return <PartnerItem key={index.toString()} partner={element} />;
        })}
      </Marquee>

      <Marquee gradient={false} direction="right" className="partners-marquee">
        {partnersBottom.map((element, index) => {
          return <PartnerItem key={index.toString()} partner={element} />;
        })}
      </Marquee>
    </>
  );
}

export { PartnersAutoScroll };
