import { useContext, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player/youtube';
import { observer } from 'mobx-react-lite';
import { StoreContext } from 'index';

Modal.setAppElement('#root');

type Props = {
  url: string;
};

const MOBILE_WIDTH = 468;

export const VideoModal = observer(({ url }: Props) => {
  const store = useContext(StoreContext);
  const videoRef = useRef<ReactPlayer>(null);
  const { innerWidth: width } = window;
  useEffect(() => {
    if (store?.appStore.showVideoModal) {
      setTimeout(() => {
        videoRef.current?.seekTo(0);
      }, 1000);
    }
  }, [store?.appStore.showVideoModal]);

  return (
    <Modal
      isOpen={store?.appStore.showVideoModal ?? false}
      onRequestClose={() => store?.appStore.setShowVideoModal(false)}
      style={{
        overlay: {
          position: 'fixed',
          height: '100%',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 1000,
          alignItems: 'center',
          justifyContent: 'center',
        },
        content: {
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          width: width > MOBILE_WIDTH ? '50vw' : '80vw',
          alignSelf: 'center',
          justifyContent: 'center',
          margin: 'auto',
          overflow: 'auto',
          borderWidth: 0,
          outline: 'none',
          padding: 0,
          backgroundColor: 'transparent',
          pointerEvents: 'none',
        },
      }}
    >
      <div className="player-wrapper">
        <ReactPlayer
          ref={videoRef}
          url={store?.appStore.VideoUrl}
          playing={store?.appStore.showVideoModal}
          width="100%"
          height="100%"
          controls={true}
          className="react-player"
        />
      </div>
    </Modal>
  );
});
