import { ContractType } from "data/store/TokenStore";

const SAND_ABI = [
  {
    constant: true,
    inputs: [{ name: "owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

export const SAND_CONTRACT: ContractType = {
  address: "0x3845badAde8e6dFF049820680d1F14bD3903a5d0",
  unit: "ether",
  abi: SAND_ABI,
};
