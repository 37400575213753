export const mainnetNetworks = {
  localhost: {
    name: "--",
    color: "#666666",
    chainId: 31337,
    rpcUrl: "http://localhost:8545",
    blockTime: 3000,
    iconUrl: "./defaultIcon.svg",
    blockDelay: 4,
    symbol: "ETH",
    blockExplorerUrl: "",
    tokenSymbolList: ["USDT"],
  },
  mainnet: {
    name: "Ethereum Mainnet",
    color: "#29b6af",
    chainId: 1,
    rpcUrl: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    blockTime: 15000,
    iconUrl: "./ETH.png",
    blockDelay: 8,
    symbol: "ETH",
    blockExplorerUrl: "https://etherscan.io",
    tokenSymbolList: [
      "AVG",
    ],
  },
  bsc: {
    name: "BNB Chain",
    color: "#7057ff",
    chainId: 56,
    rpcUrl: "https://bsc-dataseed.binance.org",
    blockTime: 4000,
    iconUrl: "./bnbchain.png",
    blockDelay: 8,
    symbol: "BNB",
    blockExplorerUrl: "https://bscscan.com",
    tokenSymbolList: [
      "AVG",
    ],   
  },
};
