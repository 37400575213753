import React, { useCallback, useEffect, useState } from 'react'
// import news1 from 'assets/images/news/1.png';
// import news2 from 'assets/images/news/2.png';
// import news3 from 'assets/images/news/3.png';
// import news4 from 'assets/images/news/4.png';
import line from 'assets/images/news/line.png';
import arrow_right from 'assets/images/news/arrow-right.png';
import axios from 'axios';

type Props = {
    img: string,
    desc: string,
    url: string,
    title: string,
    createdAt: {
        month: string,
        day: number | string
    }
}

const News = ({ img, desc, url, title, createdAt }: Props) => {
    return (
        <div className="news">
            <img src={img} alt="news" />
            <div className="news-desc">
                <h5 style={{ color: 'white' }}>{title} {createdAt.month} {createdAt.day}</h5>
                {/* <img src={line} alt="news" className='line' /> */}
                <p>
                    {desc}
                </p>
                <a href={url} className='readmore' target="_blank" rel="noopener noreferrer">
                    read more
                    <img src={arrow_right} alt="news" />
                </a>
            </div>
        </div>
    )
}

interface NewsInterface {
    title: string
    url: string
    createdAt: number
    imageUrl: string
    description: string
}

type NewsType = {
    title: string
    url: string
    createdAt: number
    imageUrl: string
    description: string
}

export default function NewsSection() {
    const [news, setNews] = useState([]);
    const [mainNews, setMainNews] = useState<NewsInterface>({
        title: '',
        url: '',
        createdAt: 0,
        imageUrl: '',
        description: '',
    });

    const fetchData = async () => {
        const data = await axios.get('https://avocado-feed.gg3700.workers.dev');

        setMainNews(data.data.data.posts[data.data.data.posts.length - 1]);
        setNews(data.data.data.posts.slice(0, 3));

        return data;
    }

    const normalizeDate = useCallback((d) => {
        const date = new Date(d);

        return {
            month: date.toLocaleString('default', { month: 'short' }),
            day: date.toLocaleString('default', { day: '2-digit' })
        };
    }, [])

    useEffect(() => {
        fetchData();
    }, [])


    return (
        <div className='news-section token-sale-section flex' >
            <div className="container">
                <div className="main-news">
                    <img src={mainNews.imageUrl} alt="news" />
                    <h5 style={{ color: 'white' }}>{mainNews.title} {normalizeDate(mainNews.createdAt).month} {normalizeDate(mainNews.createdAt).day} </h5>
                    {/* <img src={line} alt="news" className='line' /> */}
                    <p>
                        {mainNews.description}
                    </p>
                    <a href={mainNews.url} className='readmore' target="_blank" rel="noopener noreferrer">
                        read more
                        <img src={arrow_right} alt="news" />
                    </a>
                </div>
                <div className="list-news">
                    {news.map((a: NewsType) => <News img={a.imageUrl} desc={a.description} url={a.url} title={a.title} createdAt={normalizeDate(a.createdAt)} />)}
                </div>
            </div>
        </div>
    )
}
