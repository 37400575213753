import {
  AXS_CONTRACT,
  REVV_CONTRACT,
  SAND_CONTRACT,
  SLP_CONTRACT,
  AAG_CONTRACT,
  AVG_CONTRACT,
  GF_CONTRACT,
  ILV_CONTRACT,
  MC_CONTRACT,
  PATH_CONTRACT,
  THC_CONTRACT,
  THG_CONTRACT,
  USDC_CONTRACT,
  USDT_CONTRACT,
  YGG_CONTRACT,
} from 'data/tokenContractData';
import { makeAutoObservable } from 'mobx';

import { RootStore } from './RootStore';

// Object representing data of a token's contract
export type ContractType = {
  address: string;
  unit: string;
  abi: any;
};

// Object representing a token
export type TokenType = {
  name: string;
  contractData: ContractType;
};

export class TokenStore {
  rootStore: RootStore;

  tokensToTrack: TokenType[];

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);

    // initiate rootStore if we need to access other mobx stores from here

    this.rootStore = rootStore;

    this.tokensToTrack = [];
    this.tokensToTrack.push({ name: 'AXS', contractData: AXS_CONTRACT });
    this.tokensToTrack.push({ name: 'SLP', contractData: SLP_CONTRACT });
    this.tokensToTrack.push({ name: 'REVV', contractData: REVV_CONTRACT });
    this.tokensToTrack.push({ name: 'SAND', contractData: SAND_CONTRACT });
    this.tokensToTrack.push({ name: 'AAG', contractData: AAG_CONTRACT });
    this.tokensToTrack.push({ name: 'AVG', contractData: AVG_CONTRACT });
    this.tokensToTrack.push({ name: 'GF', contractData: GF_CONTRACT });
    this.tokensToTrack.push({ name: 'ILV', contractData: ILV_CONTRACT });
    this.tokensToTrack.push({ name: 'MC', contractData: MC_CONTRACT });
    this.tokensToTrack.push({ name: 'PATH', contractData: PATH_CONTRACT });
    // this.tokensToTrack.push({ name: 'THC', contractData: THC_CONTRACT });
    // this.tokensToTrack.push({ name: 'THG', contractData: THG_CONTRACT });
    this.tokensToTrack.push({ name: 'USDC', contractData: USDC_CONTRACT });
    this.tokensToTrack.push({ name: 'USDT', contractData: USDT_CONTRACT });
    this.tokensToTrack.push({ name: 'YGG', contractData: YGG_CONTRACT });
  }

  getTokensToTrack() {
    return this.tokensToTrack;
  }
}
