import { ContractType } from "data/store/TokenStore";

const USDT_ABI = [
    {
        constant: true,
        inputs: [
            {
                name: "_owner",
                type: "address"
            }
        ],
        name: "balanceOf",
        outputs: [
            {
                name: "balance",
                type: "uint256"
            }
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
];

export const USDT_CONTRACT: ContractType = {
  address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
  unit: "mwei",
  abi: USDT_ABI,
};
