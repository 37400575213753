import React from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import Countdown from 'react-countdown';
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import greenDividerTop from 'assets/images/vaults/green-divider-top.svg';
import greenDividerBottom from 'assets/images/vaults/green-divider-bottom.svg';
import goldDividerTop from 'assets/images/vaults/gold-divider-top.svg';
import goldDividerBottom from 'assets/images/vaults/gold-divider-bottom.svg';
import blueDividerTop from 'assets/images/vaults/blue-divider-top.svg';
import blueDividerBottom from 'assets/images/vaults/blue-divider-bottom.svg';
import purpleDividerTop from 'assets/images/vaults/purple-divider-top.svg';
import purpleDividerBottom from 'assets/images/vaults/purple-divider-bottom.svg';

type Props = {
  index: any;
  vault: any;
}

const getVaultColor = (index: number) => {
  if (index % 4 === 1) return "green";
  else if (index % 4 === 2) return "gold";
  else if (index % 4 === 3) return "blue";
  else return "purple";
}

const getDividerTopColor = (index: number) => {
  if (index % 4 === 1) return greenDividerTop;
  else if (index % 4 === 2) return goldDividerTop;
  else if (index % 4 === 3) return blueDividerTop;
  else return purpleDividerTop;
}

const getDividerBottomColor = (index: number) => {
  if (index % 4 === 1) return greenDividerBottom;
  else if (index % 4 === 2) return goldDividerBottom;
  else if (index % 4 === 3) return blueDividerBottom;
  else return purpleDividerBottom;
}

const getProgressBarColor = (index: number) => {
  if (index % 4 === 1) return "#B2F075";
  else if (index % 4 === 2) return "#F0C775";
  else if (index % 4 === 3) return "#75B3F0";
  else return "#DB75F0";
}

const truncateAccountNumber = (accountNum: string, isDesktopSize: boolean) => {
  const truncateLen = isDesktopSize ? 8 : 9;
  return (
    accountNum.substr(0, truncateLen) +
    "..." +
    accountNum.substr(accountNum.length - truncateLen, accountNum.length)
  );
}

const thousandSeparator = (amount: string, isUsd = true) => {
  if (!amount) return amount;

  var parts = amount.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (isUsd && parts[1]) {
    parts[1] = parts[1].length > 2 ? parts[1].substring(0, 2) : parts[1];
  }

  return parts.join(".");
}

const formatAmount = (amount: number) => {
  let nFormat;
  if (Math.abs(amount) > 999999999) {
    nFormat =
      thousandSeparator(
        (Math.sign(amount) * (Math.abs(amount) / 1000000000)).toFixed(0)
      ) + "B";
  } else if (Math.abs(amount) > 999999) {
    nFormat =
      (Math.sign(amount) * (Math.abs(amount) / 1000000)).toFixed(0) + "M";
  } else if (Math.abs(amount) > 999) {
    nFormat =
      (Math.sign(amount) * (Math.abs(amount) / 1000)).toFixed(0) + "K";
  } else if (Math.abs(amount) <= 999) {
    nFormat = (Math.sign(amount) * Math.abs(amount)).toFixed(0);
  }

  return nFormat;
}

const etherscanAddress = (address: string) => {
  return (
    "https://bscscan.com/address/" + address
  );
}

// const getHeightValue = (length: number) => {
//   return length === 1 ? "150%" : (length === 2 ? "75%" : (length === 3 ? "50%" : "35%"));
// }

export function VaultBlock({
  index,
  vault,
}: Props) {
  return (
    <div key={index}
      className={`pool-name ${getVaultColor(index)}`}
    >
      <div className={`pool-title ${getVaultColor(index)}`}>
        <label>{vault.name}</label>
        <span>
          {truncateAccountNumber(vault.address, true)}
          <a href={etherscanAddress(vault.address)} target="_blank">
            <FontAwesomeIcon icon={faUpRightFromSquare} />
          </a>
        </span>
      </div>
      <div className={`pool-apy ${getVaultColor(index)}`}>
        <img src={getDividerTopColor(index)} alt="placeholder" />
        <span>{`+${vault.apr * vault.pool_weight_max}%`}</span>
        <p>Maximum APR</p>
        <img src={getDividerBottomColor(index)} alt="placeholder" />
      </div>
      <div className="tvl-progress">
        <div className="tvl-label">
          <span>{`TVL ${formatAmount(+vault.tvl / Math.pow(10, 18))} TOKENS`}</span>
          <span>MAX TVL</span>
        </div>
        <ProgressBar
          className={`bar-progress ${getVaultColor(index)}`}
          bgColor={getProgressBarColor(index)}
          baseBgColor="#161616CC"
          isLabelVisible={false}
          completed={vault.tvl / Math.pow(10, 18)}
          maxCompleted={+vault.max_deposit}
        />
      </div>
      <div className="pool-item">
        <div className="label">
          <label>Pool Asset</label>
          <label>Staking Ends In</label>
        </div>
        <div className="data">
          <span>{vault.pool_token_name}</span>
          <span className="text-countdown">
            <Countdown
              date={vault.end_staking_time * 1000}
              zeroPadTime={2}
              renderer={props => <div>{props.days} Days {props.hours.toString().padStart(2, '0')}:{props.minutes.toString().padStart(2, '0')}:{props.seconds.toString().padStart(2, '0')}</div>}
            />
          </span>
        </div>
      </div>
    </div >
  );
}
