const alphas = {
    100: "FF",
    95: "F2",
    90: "E6",
    85: "D9",
    80: "CC",
    75: "BF",
    70: "B3",
    65: "A6",
    60: "99",
    55: "8C",
    50: "80",
    45: "73",
    40: "66",
    35: "59",
    30: "4D",
    25: "40",
    20: "33",
    15: "26",
    10: "1A",
    5: "0D",
    0: "00"
}

export type Alphas = 100 | 95 | 90 | 85 | 80 | 75 | 70 | 65 | 60 | 55 | 50 | 45 | 40 | 35 | 30 | 25| 20 | 15 | 10 | 5 | 0;

export default alphas;