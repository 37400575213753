import React from 'react';
import { AppStore } from './AppStore';
import { PriceStore } from './PriceStore';
import { BalanceStore } from './BalanceStore';
import { TokenStore } from './TokenStore';

export class RootStore {
  appStore: AppStore;
  tokenStore: TokenStore;
  priceStore: PriceStore;
  balanceStore: BalanceStore;
  constructor() {
    this.appStore = new AppStore(this);
    this.tokenStore = new TokenStore(this);
    this.priceStore = new PriceStore(this);
    this.balanceStore = new BalanceStore(this);
  }
}
