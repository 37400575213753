import React, { CSSProperties } from "react";
import FacebookIcon from "../../assets/images/icons/facebook.svg";
import YoutubeIcon from "../../assets/images/icons/youtube.svg";
import TwitterIcon from "../../assets/images/icons/twitter.svg";
import DiscordIcon from '../../assets/images/icons/discord.svg';
import MediumIcon from '../../assets/images/icons/medium.svg';
import TelegramIcon from '../../assets/images/icons/telegram.svg';

function SocialMediaLinks() {
  return (
    <div className="flex flex-column align-center social-media-section">
      <div className="social-media-title">
        <p className="footer-large">Watch how we navigate the Metaverse</p>
      </div>
      <div className="flex justify-space-between social-media-icons">
        <a
          href="https://www.facebook.com/Avocado-Guild-101425115606364"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: "none" }}
        >
          <img src={FacebookIcon} alt="Facebook" />
        </a>
        <a
          href="https://twitter.com/avocadoguild"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: "none" }}
        >
          <img src={TwitterIcon} alt="Twitter" />
        </a>
        <a
          href="https://discord.gg/avocadoguild"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: "none" }}
        >
          <img src={DiscordIcon} alt="Discord" style={{paddingTop: 3}} />
        </a>
        <a
          href="https://www.youtube.com/channel/UCoh8FvtLf3cZhAdlaCOnelQ"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: "none" }}
        >
          <img src={YoutubeIcon} alt="Youtube" />
        </a>
        <a
          href="https://avocadoguild.medium.com/"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: "none" }}
        >
          <img src={MediumIcon} alt="Medium" />
        </a>
        <a
          href="https://t.me/avocado_guild"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: "none" }}
        >
          <img src={TelegramIcon} alt="Telegram" />
        </a>
      </div>
    </div>
  );
}

export { SocialMediaLinks };
