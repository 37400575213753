import { useContext, useEffect, useRef, useState } from 'react';
import { StoreContext } from 'index';
import { useWeb3Context } from "../providers/Web3ContextProvider";

type Props = {
  connectedAccount?: string;
};

function truncateAccountNumber(accountNum: string, isDesktopSize: boolean) {
  const truncateLen = 4;
  return (
    accountNum.substr(0, truncateLen + 2) +
    "..." +
    accountNum.substr(accountNum.length - truncateLen, accountNum.length)
  );
}

function AccountDisplay({ connectedAccount = "" }: Props) {
  const { innerWidth: width } = window;
  const TABLET_WIDTH = 768;
  const { logoutOfWeb3Modal } = useWeb3Context();

  return (
    <button
      className='btn-hero btn-connect btn-header'
      onClick={logoutOfWeb3Modal}
    >
      <span style={{ fontSize: 18, paddingRight: 7, marginTop: -5 }}>&#8226;</span>
      {`${truncateAccountNumber(connectedAccount, width > TABLET_WIDTH)}`}
    </button>
  );
}

export { AccountDisplay };
