import React, { useCallback, useContext, useEffect } from "react";
import { PricesTable, PriceTicker, TOKEN_IDS } from "components";
import { observer } from "mobx-react-lite";
import { StoreContext } from "index";

type Props = {};

// BTC, ETH, AXS, SLP, REVV, SAND, (THG, DMLG)
const tokenIds = [
  TOKEN_IDS.BTC,
  TOKEN_IDS.ETH,
  TOKEN_IDS.AXS,
  TOKEN_IDS.SLP,
  TOKEN_IDS.REVV,
  TOKEN_IDS.SAND,
];

// Represents a web3 contract instance of a token
type web3Contract = {
  tokenName: string;
  tokenUnit: string;
  contract: any;
};

export const Dashboard = observer(({}: Props) => {
  const store = useContext(StoreContext);
  const account = store?.appStore.getAccounts();
  const { web3 } = window;

  // fetch user's balance of each token
  const fetchBalances = useCallback(
    async (contracts: web3Contract[]) => {
      if (Array.isArray(account) && account.length > 0) {
        // Update the user's balance of each token using the contracts:
        // First, check Ether balance
        let latestBalance = await web3.eth.getBalance(account[0]);
        latestBalance = await web3.utils.fromWei(latestBalance);
        store?.balanceStore.updateLatestBalance("ETH", latestBalance);

        // Then, fetch balance of all other tokens
        for (let tokenContract of contracts) {
          let latestBalance;

          latestBalance = await tokenContract.contract.methods
            .balanceOf(account[0])
            .call();
          latestBalance = await web3.utils.fromWei(
            latestBalance,
            tokenContract.tokenUnit
          );

          store?.balanceStore.updateLatestBalance(
            tokenContract.tokenName,
            latestBalance
          );
          // if (store?.balanceStore.getLatestBalances()) {
          //   for (let balance of store?.balanceStore.getLatestBalances()) {
          //     console.log(balance.name + balance.balance.toString());
          //   }
          // }
        }
      }
    },
    [account, store?.balanceStore, web3.eth, web3.utils]
  );

  useEffect(() => {
    if (web3) {
      // Create an array of web3 contracts for all tokens
      // that we are keeping track of (excluding Ether)
      const tokensToTrack = store?.tokenStore.getTokensToTrack();
      const contractInstances: web3Contract[] = [];

      if (tokensToTrack) {
        tokensToTrack.forEach((token) =>
          contractInstances.push({
            tokenName: token.name,
            tokenUnit: token.contractData.unit,
            contract: new web3.eth.Contract(
              token.contractData.abi,
              token.contractData.address
            ),
          })
        );
      }

      // Update the user's balance of each token using the contracts
      fetchBalances(contractInstances);
    }
  }, [web3, store?.balanceStore, store?.tokenStore, fetchBalances]);

  return (
    <div className="dashboard-page">
      <div className="title-md dashboard-title">Dashboard</div>
      <PriceTicker tokenIds={tokenIds} />
      <div className="flex align-center justify-space-between prices-content">
        <PricesTable />
      </div>
    </div>
  );
});
