import React, { useCallback, useContext, useEffect, useRef } from "react";
import { StoreContext } from "index";
import { observer } from "mobx-react-lite";
import { PricesMarquee } from "components";
import { PriceType } from "data/store/PriceStore";

// BTC, ETH, AXS, SLP, REVV, SAND, THG, DMLG
export const TOKEN_IDS = {
  BTC: "9",
  ETH: "125",
  AXS: "179874",
  SLP: "181409",
  REVV: "179635",
  SAND: "179676",
};

type Props = {
  tokenIds?: string[];
};

const getNameOfTokenId = (tokenId: string) => {
  switch (tokenId) {
    case TOKEN_IDS.BTC:
      return "BTC";
    case TOKEN_IDS.ETH:
      return "ETH";
    case TOKEN_IDS.AXS:
      return "AXS";
    case TOKEN_IDS.SLP:
      return "SLP";
    case TOKEN_IDS.REVV:
      return "REVV";
    case TOKEN_IDS.SAND:
      return "SAND";
    default:
      return "";
  }
};

// Function to lengthen the ticker data so that it wraps around in the marquee
function multiplyLengthOfArray(array: PriceType[], n: number) {
  let newArr: PriceType[] = [];

  for (let i = 0; i < n; i++) {
    newArr = newArr.concat(array);
  }
  return newArr;
}

const PriceTicker = observer(({ tokenIds = [] }: Props) => {
  const store = useContext(StoreContext);
  const { document } = window;
  const ws = useRef<WebSocket | null>(null);
  const unsubTimer = useRef<any>(null);
  const subTimer = useRef<any>(null);
  const inactiveTimer = useRef<any>(null);

  const API_KEY = process.env.REACT_APP_CRYPTOWATCH_API_KEY;

  const baseUrl = "wss://stream.cryptowat.ch/connect?apikey=";

  // Format price ticker resources list based on token ids
  const resources = useRef<string[]>(
    tokenIds.map((id) => `instruments:${id}:ohlc`)
  );

  // Function to fetch data from stream for
  // 400 milliseconds every 1 second.
  // Can adjust speed of price ticker updates here
  const fetchDataFromStream = useCallback(() => {
    // Do not subscribe to stream if no account connected
    const accounts = store?.appStore.getAccounts();
    if (Array.isArray(accounts) && accounts.length === 0) {
      return;
    }

    if (ws.current?.readyState !== WebSocket.OPEN) {
      return;
    }

    console.log("start stream");
    subscribe(ws.current, resources.current);

    unsubTimer.current = setTimeout(function () {
      unsubscribe(ws.current, resources.current);
    }, 400);

    subTimer.current = setTimeout(fetchDataFromStream, 1000);
  }, [store?.appStore]);

  // Helper function to unsubscribe from stream
  const unsubscribeFromWebSocketStream = useCallback(() => {
    if (ws.current?.readyState === WebSocket.OPEN) {
      console.log("stop stream");
      unsubscribe(ws.current, resources.current);
    }
  }, []);

  // Event callbacks to pause stream subscription when needed
  const onVisibilityChange = useCallback(() => {
    // Clear all timers
    clearTimeout(unsubTimer.current);
    clearTimeout(subTimer.current);

    if (document.hasFocus()) {
      console.log("document uncovered");
      fetchDataFromStream();
    } else {
      console.log("document hidden");
      unsubscribeFromWebSocketStream();
    }
  }, [document, fetchDataFromStream, unsubscribeFromWebSocketStream]);

  const onBlur = useCallback(() => {
    // Clear all timers
    clearTimeout(unsubTimer.current);
    clearTimeout(subTimer.current);
    console.log("window blurred");
    unsubscribeFromWebSocketStream();
  }, [unsubscribeFromWebSocketStream]);

  const onFocus = useCallback(() => {
    // Clear all timers
    clearTimeout(unsubTimer.current);
    clearTimeout(subTimer.current);
    console.log("window focussed");
    fetchDataFromStream();
  }, [fetchDataFromStream]);

  useEffect(() => {
    // Create websocket to connect to crytowat.ch web socket API

    ws.current = new WebSocket(baseUrl + API_KEY);

    ws.current.binaryType = "blob";

    ws.current.onopen = function (event: any) {
      console.log("Websocket connection opened");
    };

    ws.current.onclose = function (event: any) {
      console.log("Websocket connection closed");
    };

    ws.current.onmessage = function (msg: any) {
      if (msg.data instanceof Blob) {
        const reader = new FileReader();

        reader.onload = () => {
          // console.log("Raw result: " + reader.result);

          if (reader.result) {
            // Parse raw data as JSON

            const data = reader.result;

            const d = JSON.parse(data.toString());

            // The server will always send an AUTHENTICATED signal when you establish a valid connection

            // At this point you can subscribe to resources

            if (
              d.authenticationResult &&
              d.authenticationResult.status === "AUTHENTICATED"
            ) {
              console.log("Streaming prices...");

              // Start periodically subscribing to stream
              fetchDataFromStream();

              // Add event listeners to pause stream subscription when needed
              document.addEventListener("visibilitychange", onVisibilityChange);
              window.addEventListener("blur", onBlur);
              window.addEventListener("focus", onFocus);

              // Set timeout of stream after 2 hours (7200000 milliseconds)
              inactiveTimer.current = setTimeout(() => {

                console.log('Timeout - unsubscribe from stream!');
                // Clear all timers
                clearTimeout(unsubTimer.current);
                clearTimeout(subTimer.current);

                // Clear all event listeners
                document.removeEventListener(
                  "visibilitychange",
                  onVisibilityChange
                );
                window.removeEventListener("blur", onBlur);
                window.removeEventListener("focus", onFocus);

                if (ws.current?.readyState === WebSocket.OPEN) {
                  console.log("stop stream");
                  unsubscribe(ws.current, resources.current);
                }
              }, 7200000);
            }

            // Market data comes in a marketUpdate

            if (
              d.marketUpdate &&
              d.marketUpdate.market &&
              d.marketUpdate.intervalsUpdate
            ) {
              // console.log("Reading from stream");

              const networkIntervals = d.marketUpdate.intervalsUpdate.intervals;

              // Sort intervals list based on interval length (ascending)

              const intervals = networkIntervals.sort(
                (a: any, b: any) => a.periodName - b.periodName
              );

              if (intervals.length > 0) {
                // Console log all intervals for testing

                // for (let interval of intervals) {
                //   console.log(
                //     `${getNameOfTokenId(
                //       d.marketUpdate.market.currencyPairId
                //     )} interval closing price: ${interval.periodName} ${
                //       interval.ohlc.closeStr
                //     }`
                //   );
                // }

                // Store final closing price in latestPrices in price store

                store?.priceStore.updateLatestPrice(
                  getNameOfTokenId(d.marketUpdate.market.currencyPairId),

                  parseFloat(intervals[0].ohlc.closeStr)
                );
              }
            }
          } else {
            console.log("Could not read message");
          }
        };

        reader.readAsText(msg.data);
      } else {
        console.log("Message is not a blob");
      }
    };
    return function cleanup() {
      // Clear all timers
      clearTimeout(unsubTimer.current);
      clearTimeout(subTimer.current);
      clearTimeout(inactiveTimer.current);

      // Clear all event listeners
      document.removeEventListener("visibilitychange", onVisibilityChange);
      window.removeEventListener("blur", onBlur);
      window.removeEventListener("focus", onFocus);

      if (ws.current?.readyState === WebSocket.OPEN) {
        console.log("stop stream and close web socket");
        unsubscribe(ws.current, resources.current);
        ws.current?.close();
      }
    };
  }, [
    tokenIds,
    store?.priceStore,
    store?.appStore,
    document,
    fetchDataFromStream,
    onBlur,
    onFocus,
    onVisibilityChange,
  ]);

  // Helper method for subscribing to resources

  function subscribe(conn: any, resources: Array<string>) {
    conn.send(
      JSON.stringify({
        subscribe: {
          subscriptions: resources.map((resource) => {
            return { streamSubscription: { resource: resource } };
          }),
        },
      })
    );
    console.log("subscribed!");
  }

  // Helper method for unsubscribing to resources

  function unsubscribe(conn: any, resources: Array<string>) {
    conn.send(
      JSON.stringify({
        unsubscribe: {
          subscriptions: resources.map((resource) => {
            return { streamSubscription: { resource: resource } };
          }),
        },
      })
    );
    console.log("unsubscribed");
  }

  // Get latest prices to display

  const pricesToDisplay = store?.priceStore.getLatestPrices();

  if (pricesToDisplay) {
    return <PricesMarquee prices={multiplyLengthOfArray(pricesToDisplay, 5)} />;
  } else {
    return null;
  }
});

export { PriceTicker };
