import { ContractType } from "data/store/TokenStore";

const YGG_ABI = [
    {
        constant: true,
        inputs: [
            {
                name: "_owner",
                type: "address"
            }
        ],
        name: "balanceOf",
        outputs: [
            {
                name: "balance",
                type: "uint256"
            }
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
];

export const YGG_CONTRACT: ContractType = {
  address: "0x25f8087ead173b73d6e8b84329989a8eea16cf73",
  unit: "ether",
  abi: YGG_ABI,
};
