import { PriceType } from "data/store/PriceStore";
import { StoreContext } from "index";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { CSSProperties, useContext, useEffect, useState } from "react";

type Props = {
  prices?: PriceType[];
};

const strNumberWithCommas = (strNum: string) => {
  return strNum.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function getFullNameOfToken(token: string) {
  switch (token) {
    case "BTC":
      return "Bitcoin ";
    case "ETH":
      return "Ether ";
    case "AXS":
      return "Axie Infinity Shards ";
    case "SLP":
      return "Smooth Love Potion ";
    default:
      return "";
  }
}

const PricesTable = observer(({}: Props) => {
  const store = useContext(StoreContext);

  const latestBalances = store?.balanceStore.getLatestBalances();

  return (
    <div className="prices-table">
      <table style={{ width: "100%" }}>
        <colgroup>
          <col span={1} style={{ width: "50%" }} />
          <col span={1} style={{ width: "25%" }} />
          <col span={1} style={{ width: "25%" }} />
        </colgroup>
        <thead>
          <tr>
            <th>
              <p className="headline price-table-header">Name</p>
            </th>
            <th>
              <p className="headline price-table-header">Balance</p>
            </th>
            <th>
              <p className="headline price-table-header">Price</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {latestBalances
            ? latestBalances.map((balance, index) => {

              const tokenPrice = store?.priceStore.getPriceOf(balance.name);

                // Only show the balance if it is above zero
                if (
                  balance.name !== "BTC" &&
                  parseFloat(balance.balance) > 0
                ) {
                  const userBalanceNum = parseFloat(balance.balance);
                  return (
                    <tr key={index.toString()}>
                      <td>
                        <p className="body token-name">{`${getFullNameOfToken(
                          balance.name
                        )}${balance.name}`}</p>
                      </td>
                      <td>
                        <p className="body token-balance">
                          {userBalanceNum.toFixed(6)}
                        </p>
                      </td>
                      <td>
                        <p className="body token-balance-price">{tokenPrice ? `$${strNumberWithCommas(
                          (tokenPrice * userBalanceNum).toFixed(2)
                        )}` : ''}</p>
                      </td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })
            : null}
        </tbody>
      </table>
    </div>
  );
});

export { PricesTable };
