import { useState } from 'react';

type DropdownItem = {
  title: string;
  href: string;
  icon?: any;
};

type Props = {
  title: string;
  items: DropdownItem[];
};

function HeaderDropdownMenu({ title, items }: Props) {
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <div className='dropdown-menu'>
      <button
        className='btn-text dropdown'
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {title}
      </button>
      <div
        className={`dropdown-content${showDropdown ? ' show-dropdown' : ''}`}
      >
        {items.map((item) => (
          <a
            href={item.href}
            target='_blank'
            rel='noreferrer noopener'
            style={{ textDecoration: 'none' }}
            className='flex align-center'
          >
            {item.icon && (
              <img src={item.icon} alt={item.title} className='link-icon' />
            )}
            <p className='headline'>{item.title}</p>
          </a>
        ))}
      </div>
    </div>
  );
}

export { HeaderDropdownMenu };
export type { DropdownItem };
