import { PriceType } from "data/store/PriceStore";
import React, { CSSProperties, useEffect } from "react";
import Marquee from "react-fast-marquee";

type Props = {
  prices?: PriceType[];
};

const strNumberWithCommas = (strNum: string) => {
  return strNum.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function PricesMarquee({ prices = [] }: Props) {
  return (
    <div className="prices-marquee">
      <Marquee gradient={false}>
        {prices.map((element, index) => {
          return (
            <div className="prices-ticker-item" key={index.toString()}>
              <p className="body">{`${element.name}:$${strNumberWithCommas(
                element.price.toFixed(2)
              )}`}</p>
            </div>
          );
        })}
      </Marquee>
      <div className="prices-marquee-gradient"></div>
    </div>
  );
}

export { PricesMarquee };
